/* Styles pour la section hero */
.hero {
    position: relative; /* Nécessaire pour l'overlay */
    height: 100vh; /* Hauteur de l'écran */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white; /* Couleur du texte */
    background-size: cover; /* S'adapte à l'écran */
    background-position: center center; /* Centré */
    background-repeat: no-repeat; /* Pas de répétition */
    z-index: 1; /* Contenu devant l'overlay */
    overflow: hidden; /* Cache les débordements */
    transition: background 1s ease-in-out; /* Transition pour le changement d'arrière-plan */
    padding: 0 2rem; /* Ajout de padding latéral pour les petits écrans */
}

/* Overlay sombre pour rendre le texte plus lisible */
.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Couleur noire avec opacité à 50% */
    z-index: -1; /* L'overlay se trouve derrière le contenu */
    transition: opacity 0.5s ease; /* Transition pour l'opacité de l'overlay */
}

/* Animation pour le texte */
.hero h1 {
    opacity: 0; /* Commence caché */
    transform: translateY(20px); /* Légèrement en bas */
    animation: fadeInUp 0.8s forwards; /* Animation d'entrée */
    font-size: 2.5rem; /* Taille par défaut */
    margin-bottom: 1rem; /* Espacement sous le titre */
    text-align: center; /* Centrage du texte */
}

.hero p {
    opacity: 0; /* Commence caché */
    transform: translateY(20px); /* Légèrement en bas */
    animation: fadeInRight 0.8s forwards 0.2s; /* Animation d'entrée décalée */
    font-size: 1.2rem; /* Taille par défaut */
    text-align: center; /* Centrage du texte */
    margin: 0 0 1.5rem; /* Marge entre le paragraphe et le bouton */
}

.hero h1,
.hero p {
    z-index: 2; /* Assure que le texte est au-dessus de l'overlay */
}

/* Styles pour le bouton */
.cta-button {
    background-color: #ff5733;
    color: white;
    padding: 1rem 2rem;
    border-radius: 5px;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Transition pour le changement de couleur */
    text-align: center;
}

.cta-button:hover {
    background-color: #ff2b00; /* Change la couleur au survol */
}

/* Animation pour le bouton */
.cta-button {
    opacity: 0; /* Commence caché */
    animation: fadeInLeft 0.8s forwards 0.4s; /* Animation d'entrée avec délai */
}

/* Responsive : Ajustements pour tablettes */
@media (max-width: 1024px) {
    .hero h1 {
        font-size: 2rem; /* Taille réduite */
    }

    .hero p {
        font-size: 1.1rem; /* Taille légèrement réduite */
    }

    .cta-button {
        font-size: 1.1rem; /* Taille légèrement réduite */
        padding: 0.8rem 1.8rem; /* Padding ajusté */
    }
}

/* Responsive : Ajustements pour mobiles */
@media (max-width: 768px) {
    .hero {
        padding: 0 1rem; /* Réduction du padding latéral */
    }

    .hero h1 {
        font-size: 1.8rem; /* Taille plus petite pour les mobiles */
    }

    .hero p {
        font-size: 1rem; /* Taille encore plus petite */
        margin-bottom: 1rem; /* Réduction de l'espacement */
    }

    .cta-button {
        font-size: 1rem; /* Taille réduite pour les mobiles */
        padding: 0.7rem 1.5rem; /* Moins de padding */
    }
}
