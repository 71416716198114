/* Style global du corps */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  line-height: 1.6;
}

/* Barre de progression */
.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #c20000;
  z-index: 999;
}

.progress {
  height: 100%;
  background-color: #c20000;
  transition: width 0.3s ease-in-out;
}

/* Styles des titres */
h1, h2 {
  text-align: center;
  margin-bottom: 1rem;
  color: #333;
}

/* Styles des liens */
a {
  text-decoration: none;
  color: #007bff;
  transition: color 0.3s ease;
}

a:hover {
  color: #0056b3;
}

/* Styles des sections */
section {
  padding: 80px 20px;
  text-align: center;
  position: relative;
}

/* Styles du header et footer */
header, footer {
  padding: 20px 0;
  background-color: #333;
  color: #fff;
  text-align: center;
}

footer {
  margin-top: 20px;
}

/* Réduire la taille des titres sur mobile */
@media (max-width: 768px) {
  h1, h2 {
    font-size: 1.5rem;
  }

  .container {
    padding: 15px;
  }
}

/* Sidebar container */
.sidebar {
  position: fixed;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 40px; /* Espacement ajusté entre les boutons */
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-item {
  position: relative;
}

.sidebar-link {
  display: flex;
  align-items: center;
  gap: 10px;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
}

.circle {
  width: 30px;
  height: 30px;
  background-color: #6a0dad; /* Violet initial */
  border-radius: 50%;
  transition: transform 0.3s ease, background-color 0.3s ease;
  margin-top: 35px;
}

.sidebar-link:hover .circle {
  transform: scale(1.3);
  background-color: #ffffff; /* Blanc au survol */
}

.sidebar-link:hover .bubble {
  opacity: 1;
  transform: scale(1) translateX(50px); /* Apparition et décalage fluide */
}

.bubble {
  position: absolute;
  left: 40px; /* Position à droite du cercle */
  top: 50%;
  transform: scale(0) translateX(0); /* Initialement réduite et centrée */
  transform-origin: left center;
  opacity: 0;
  padding: 10px 15px;
  background-color: #6a0dad; /* Violet de la bulle */
  color: #ffffff; /* Texte blanc */
  border-radius: 10px; /* Forme arrondie */
  transition: transform 0.3s ease, opacity 0.3s ease;
  white-space: nowrap; /* Empêche le texte de passer à la ligne */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombre pour effet 3D */
}

.bubble:after {
  content: '';
  position: absolute;
  left: -10px; /* Ajuste la flèche à la bordure de la bulle */
  top: 50%;
  transform: translateY(-50%);
  border: 5px solid transparent;
  border-right-color: #6a0dad; /* Couleur de la flèche */
}

/* Styles responsive pour la sidebar */
@media (max-width: 1024px) { /* Tablettes */
  .sidebar {
    left: 10px; /* Rapprocher la sidebar du bord */
  }

  .circle {
    width: 25px;
    height: 25px;
  }

  .bubble {
    left: 35px; /* Ajuster la position de la bulle */
    font-size: 12px; /* Réduire la taille du texte */
    padding: 8px 10px;
  }

  .bubble:after {
    left: -8px; /* Ajuster la flèche */
  }
}

@media (max-width: 768px) { /* Smartphones */
  .sidebar {
    left: 5px; /* Encore plus proche du bord */
    gap: 30px; /* Réduire l'espacement entre les cercles */
  }

  .circle {
    width: 20px;
    height: 20px;
  }

  .bubble {
    left: 30px; /* Réduction supplémentaire */
    font-size: 10px;
    padding: 6px 8px;
  }

  .bubble:after {
    left: -6px; /* Positionner la flèche plus près */
  }
}

@media (max-width: 480px) { /* Petits téléphones */
  .sidebar {
    top: 55%; /* Ajuster la position verticale */
    gap: 20px; /* Réduire davantage l'espacement */
  }

  .circle {
    width: 18px;
    height: 18px;
  }

  .bubble {
    left: 25px;
    font-size: 9px;
    padding: 5px 6px;
  }

  .bubble:after {
    left: -5px;
  }
}
