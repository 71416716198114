/* Styles généraux */
.click-entertainment {
    font-family: Arial, sans-serif;
    color: #f8f9fa; /* Texte clair pour contraste */
    line-height: 1.6;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background: #000; /* Fond noir */
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.05) 25%, transparent 25%, transparent 75%, rgba(255, 255, 255, 0.05) 75%, rgba(255, 255, 255, 0.05)),
                      linear-gradient(45deg, rgba(255, 255, 255, 0.05) 25%, transparent 25%, transparent 75%, rgba(255, 255, 255, 0.05) 75%, rgba(255, 255, 255, 0.05));
    background-size: 20px 20px; /* Taille des carreaux */
}

h1 {
    text-align: center;
    color: #ffcc00; /* Couleur or pour le titre */
    margin-bottom: 20px;
}

.section {
    margin-bottom: 40px;
}

h2 {
    color: #ffcc00;
    margin-bottom: 10px;
}

h3 {
    color: #4b0082;
    margin-bottom: 10px;
}

p, ul {
    margin-bottom: 20px;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
}

ul li {
    margin-bottom: 10px;
}

.contact-info {
    font-weight: bold;
    margin-top: 20px;
}

.contact-info p {
    margin: 5px 0;
}

