/* Footer principal */
.footer {
    background-color: #000; /* Fond noir */
    color: #fff; /* Texte blanc */
    position: relative;
    width: 100%;
    display: flex; /* Disposition en ligne */
    flex-wrap: wrap; /* Permet aux éléments de passer à la ligne si nécessaire */
    align-items: center; /* Centre verticalement les éléments */
    justify-content: space-between; /* Espacement entre les sections */
    padding: 1.5rem 2rem; /* Ajout de padding interne */
    box-sizing: border-box;
}

.footer:hover {
    background-color: #000; /* Fond noir au survol */
}

/* Liens du footer */
.footer-link {
    color: #fff; /* Texte blanc */
    text-decoration: none;
    margin: 0 1rem; /* Espacement entre les liens */
    transition: color 0.3s ease;
}

.footer-link:hover {
    color: #ff5733; /* Couleur d'accentuation au survol */
}

/* Section des icônes sociales */
.social-icons {
    display: flex; /* Disposition en ligne */
    gap: 1rem; /* Espacement entre les icônes */
}

.social-icons a {
    color: #fff; /* Texte blanc */
    transition: all 0.3s ease;
    font-size: 1.2rem;
}

.social-icons a:hover {
    color: #ff5733; /* Couleur d'accentuation au survol */
    transform: scale(1.2); /* Agrandissement au survol */
}

/* Répartition du contenu */
.footer .footer-content {
    display: flex;
    align-items: center; /* Centre les éléments verticalement */
    flex-wrap: wrap; /* Permet aux éléments de s'ajuster sur petits écrans */
    gap: 1rem; /* Espacement entre les groupes */
}

/* Responsive : Ajustements pour tablettes */
@media (max-width: 1024px) {
    .footer {
        flex-direction: column; /* Affiche les sections en colonne */
        align-items: center; /* Centre le contenu */
    }

    .footer .footer-content {
        justify-content: center; /* Centrer les éléments */
    }

    .footer-link {
        margin: 0.5rem; /* Réduit l'espacement sur petits écrans */
    }
}

/* Responsive : Ajustements pour mobiles */
@media (max-width: 768px) {
    .footer {
        padding: 1rem; /* Réduit le padding global */
    }

    .social-icons {
        justify-content: center; /* Centre les icônes sociales */
    }

    .footer-link {
        font-size: 0.9rem; /* Réduit la taille des liens */
    }

    .social-icons a {
        font-size: 1rem; /* Réduit légèrement les icônes sociales */
    }
}