/* Positionnement de la barre latérale de contact à droite */
.contact-sidebar {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: #111;
    border-radius: 10px 0 0 10px;
    box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.3);
    padding: 1rem;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 60px; /* Largeur réduite au début */
    transition: width 0.3s ease; /* Transition pour l'agrandissement */
}

/* Effet au survol de la sidebar pour qu'elle s'agrandisse */
.contact-sidebar:hover {
    width: 200px; /* Largeur complète lorsqu'on survole */
}

/* Style des éléments de contact */
.contact-item {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-size: 1rem;
    transition: transform 0.3s ease;
}

/* Icônes */
.contact-icon {
    font-size: 1.5rem;
    background-color: #ffd700;
    padding: 10px;
    border-radius: 50%;
    color: #111;
    transition: background-color 0.3s ease;
}

/* Changements de couleur au survol */
.contact-item:hover .contact-icon {
    background-color: #4b0082;
}

/* Liens de contact */
.contact-link {
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: nowrap; /* Empêche les éléments de passer à la ligne */
}

/* Texte de l'élément */
.contact-item span {
    font-weight: bold;
}

/* Effet de survol pour ajouter de l'ombre */
.contact-item:hover span {
    color: #ffd700;
}

/* Responsiveness - Cache la sidebar dans les petits écrans */
@media (max-width: 768px) {
    .contact-sidebar {
        width: 40px; /* Moins d'espace sur petits écrans */
        padding: 0.5rem;
    }

    .contact-item {
        font-size: 0.8rem; /* Plus petite taille d'icône */
    }

    .contact-icon {
        font-size: 1rem;
        padding: 5px; /* Réduire le padding pour les icônes */
    }
}

@media (max-width: 480px) {
    .contact-sidebar {
        width: 20px; /* Encore moins d'espace sur très petits écrans */
        padding: 0.2rem;
    }

    .contact-item {
        font-size: 0.4rem; /* Encore plus petite taille d'icône */
    }

    .contact-icon {
        font-size: 0.6rem;
        padding: 2px; /* Réduire encore plus le padding pour les icônes */
    }
}