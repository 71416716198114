/* Section principale avec un background statique et ombre */
.qui-sommes-nous {
    padding: 4rem 2rem;
    background: linear-gradient(45deg, #000, #4b0082, #ffd700); /* Dégradé vibrant */
    color: white;
    text-align: center;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* Ombre principale */
    border-radius: 10px; /* Ajout d'une légère bordure arrondie */
    background-color: rgba(0, 0, 0, 0.7); /* Overlay sombre avec plus d'opacité */
    z-index: 0;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.5); /* Ombre interne */
}

/* Overlay transparent */
.qui-sommes-nous::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Overlay sombre */
    z-index: 0;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.5); /* Ombre interne subtile */
}

/* Positionnement du contenu */
.qui-sommes-nous > * {
    position: relative;
    z-index: 1;
}

/* Titre de la section */
.qui-sommes-nous h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
    color: white;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8); /* Ombre pour le titre */
}

/* Présentation de l'entreprise */
.entreprise-presentation {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 4rem;
}

.entreprise-logo {
    max-width: 200px;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.entreprise-texte {
    max-width: 500px;
    text-align: left;
}

.entreprise-texte h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    color: white;
}

.entreprise-texte p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: rgba(255, 255, 255, 0.9);
}

/* Section équipe */
.equipe-section h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
    color: #ffd700;
}

/* Grille des cartes */
.equipe-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
}

/* Carte membre */
.equipe-card {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 1.5rem;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
}

/* Carte au survol */
.equipe-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
}

/* Photo du membre */
.photo-container {
    width: 100px;
    height: 100px;
    margin: 0 auto 1rem;
    overflow: hidden;
    border-radius: 50%;
    border: 3px solid #ffd700;
}

.equipe-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Détails du membre */
.equipe-name {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: white;
}

.equipe-role {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    color: #ffd700;
    font-weight: bold;
}

.equipe-description {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.8);
}

/* Animation de l'éclair pour Yann */
.equipe-card.yann {
    position: relative;
}

.equipe-card.yann::before {
    content: "";
    position: absolute;
    top: -150%;
    left: 50%;
    width: 100%;
    height: 150%;
    background: linear-gradient(135deg, transparent 10%, #ffd700 30%, transparent 50%, #ffd700 70%, transparent 90%);
    transform: translateX(-50%) rotate(45deg);
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-out;
}

.equipe-card.yann:hover::before {
    opacity: 1;
    animation: eclairAnimation 0.8s forwards;
}

/* Animation de l'éclair */
@keyframes eclairAnimation {
    0% {
        top: -150%;
        opacity: 0;
        transform: translateX(-50%) rotate(45deg);
    }
    50% {
        top: 10%;
        opacity: 1;
        transform: translateX(-50%) rotate(45deg);
    }
    100% {
        top: 80%;
        opacity: 0;
        transform: translateX(-50%) rotate(45deg);
    }
}

/* Responsive ajustement */
@media (max-width: 768px) {
    .entreprise-presentation {
        flex-direction: column;
        text-align: center;
    }

    .entreprise-texte {
        text-align: center;
        margin-top: 2rem;
    }

    .equipe-grid {
        grid-template-columns: 1fr;
    }
}