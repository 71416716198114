/* Section Vidéo avec opacité sur l'arrière-plan */
.video-section {
    position: relative;
    height: 90vh; /* Augmente la hauteur pour mieux inclure la vidéo */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #D0874A; /* Orange terreux, chaud et accueillant */
    overflow: hidden; /* Empêche le débordement */
}

/* Superposition sombre */
.video-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black; /* Vrai noir */
    z-index: 0; /* Derrière le contenu */
}

/* Style de la vidéo */
.company-video {
    width: 200%;
    height: 200%;
    object-fit: cover;
    filter: brightness(0.7); /* Vidéo plus sombre pour le texte */
    transition: transform 0.5s ease; /* Transition à l'entrée */
}

/* Animation de montée de la vidéo */
.video-section:hover .company-video {
    transform: scale(1.05); /* Agrandit légèrement au survol */
}

/* Conteneur de la vidéo */
.video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1; /* Au-dessus de l'opacité */
    margin-top: 70px; /* Ajoute un espace au-dessus pour descendre la vidéo */
}

/* Logo Positionné en Haut */
.logo {
    position: absolute; /* Positionnement absolu */
    top: 0; /* Collé en haut */
    left: 0; /* Collé à gauche */
    width: 100%; /* Prend toute la largeur de la section */
    height: 100%; /* Prend toute la hauteur de la section */
    z-index: 2; /* S'assurer qu'il est au-dessus de l'image de fond */
}

/* Image du logo */
.logo-image {
    width: 100%; /* S'assure que le logo occupe toute la section */
    height: 100%; /* S'assure que le logo occupe toute la section */
    object-fit: cover; /* Conserve le rapport d'aspect */
    opacity: 0.5; /* Ajuste l'opacité si nécessaire */
}

/* Wrapper pour la vidéo */
.video-wrapper {
    position: relative;
    width: 90%; /* Augmente la largeur pour occuper plus d'espace */
    max-width: 1200px; /* Augmente la largeur maximale */
    height: 60%; /* Augmente la hauteur */
    overflow: hidden;
    border-radius: 15px; /* Bords arrondis pour un effet moderne */
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); /* Ajout d'une ombre */
    display: flex; /* Pour aligner le contenu s'il y a lieu */
    align-items: center; /* Centrer verticalement */
    justify-content: center; /* Centrer horizontalement */
    margin: 0 auto; /* Centre horizontalement le conteneur */
}

/* Contrôle du volume placé sur la vidéo */
.volume-control {
    position: absolute;
    bottom: 10%;
    right: 5%; /* Positionné en bas à droite de la vidéo */
    display: flex;
    align-items: center;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    border-radius: 10px;
    opacity: 0; /* Contrôles masqués par défaut */
    transition: opacity 0.3s ease; /* Transition douce pour l'apparition des contrôles */
}

/* Volume slider avec transition pour une meilleure fluidité */
#volume-slider {
    width: 150px;
    cursor: pointer;
    transition: transform 0.3s ease; /* Douce transition lors du changement de volume */
}

.volume-control label {
    color: white;
    margin-right: 10px;
}

.mute-btn {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.mute-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

/* Styles pour les contrôles de skip */
.skip-controls {
    position: absolute;
    bottom: 15%; /* Positionné légèrement au-dessus des contrôles de volume */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 20px; /* Espacement entre les boutons */
    z-index: 3;
    opacity: 0; /* Contrôles masqués par défaut */
}

.skip-btn {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.skip-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

/* Animation pour les contrôles au survol */
.video-wrapper:hover .volume-control,
.video-wrapper:hover .skip-controls {
    opacity: 1; /* Affiche les contrôles au survol */
}

/* Animation du bouton Play/Pause */
.play-pause-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    padding: 20px;
    border-radius: 50%;
    font-size: 2rem;
    cursor: pointer;
    z-index: 4;
    transition: background-color 0.3s ease;
    opacity: 0; /* Masqué par défaut */
}

.play-pause-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

/* Affiche le bouton Play/Pause au survol */
.video-wrapper:hover .play-pause-btn {
    opacity: 1;
}

/* Animations */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes zoomInBlur {
    0% {
        opacity: 0;
        transform: scale(0.8);
        filter: blur(10px); /* Ajoute un effet de flou */
    }
    50% {
        opacity: 0.5;
        transform: scale(1);
        filter: blur(5px); /* Réduit le flou progressivement */
    }
    100% {
        opacity: 1;
        transform: scale(1);
        filter: blur(0); /* Disparition totale du flou */
    }
}

/* Appliquer les animations à tous les textes de la section */
.video-overlay h2, .video-overlay p {
    animation: zoomInBlur 1.5s ease-out forwards;
    opacity: 0; /* Commence transparent */
}

.video-overlay h2 {
    font-size: 3rem; /* Taille réduite pour le titre */
}

.video-overlay p {
    font-size: 1.3rem; /* Taille réduite pour le texte */
}

/* Ajustements pour l'apparence générale */
.video-overlay {
    color: white;
    text-align: center;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 1.5rem;
    border-radius: 10px;
    animation: zoomInBlur 1.5s ease-out forwards; /* Appliquer l'animation à toute la zone */
    max-width: 80%; /* Réduire la largeur pour un look plus propre */
    margin: 20px auto; /* Centrer avec un espace */
    opacity: 0; /* Commence transparent */
}

/* Styles pour appareils mobiles */
@media (max-width: 768px) {
    .video-section {
        height: 60vh; /* Réduit la hauteur sur mobile */
    }

    .logo {
        width: 80%; /* Réduit la taille du logo sur mobile */
        height: auto; /* Ajuste la hauteur automatiquement */
    }

    .video-wrapper {
        width: 90%; /* Réduit la largeur de la vidéo sur mobile */
    }

    .video-overlay h2 {
        font-size: 2rem; /* Réduit la taille du titre sur mobile */
    }

    .video-overlay p {
        font-size: 1rem; /* Réduit la taille du texte sur mobile */
    }

    .volume-control {
        bottom: 5%; /* Ajuste la position du contrôle de volume */
    }

    #volume-slider {
        width: 100px; /* Réduit la largeur du slider */
    }

    .mute-btn {
        padding: 8px 15px; /* Ajuste le padding sur mobile */
    }
}

/* Styles pour le logo */
.logo {
    display: flex; /* Affichage en flex pour aligner l'image et le texte */
    align-items: center; /* Centrer verticalement */
    mix-blend-mode: multiply;
}

.logo-img {
    width: 200px; /* Largeur de l'image */
    height: auto; /* Hauteur automatique pour conserver les proportions */
    margin-right: 10px; /* Espacement entre l'image et le texte */
    margin-top: 80px;
}

/* Styles pour le texte du logo */
.logo-text {
    font-size: 1.8em; /* Taille du texte */
    font-weight: bold; /* Gras pour le texte */
    color: #ffffff; /* Couleur du texte du logo en blanc */
}

/* Ajout de la disparition des contrôles après 5 secondes */
.video-wrapper:hover .volume-control,
.video-wrapper:hover .skip-controls {
    opacity: 1;
    animation: showControls 9s forwards;
}
.video-wrapper:hover .play-pause-btn {
    opacity: 1;
    animation: showControls 9s forwards;
}

@keyframes showControls {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

