/* Section principale avec effet de fondu et ombres */
.why-choose {
    padding: 4rem 1rem;
    text-align: center;
    color: white;
    position: relative;
    background: linear-gradient(45deg, black, violet, gold); /* Dégradé initial */
    background-size: cover; /* Taille pour couvrir toute la section */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6); /* Ombre principale */
}

/* Overlay transparent */
.why-choose::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Overlay sombre avec plus d'opacité */
    z-index: 0;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.5); /* Ombre interne */
}

/* Positionnement du contenu */
.why-choose > * {
    position: relative;
    z-index: 1;
}

/* Titre de la section */
.why-choose h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8); /* Ombre pour le texte */
}

/* Grille des fonctionnalités */
.features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 colonnes */
    gap: 2rem; /* Augmenter l'écart entre les éléments */
    justify-items: center;
}

/* Style des fonctionnalités */
.feature-item {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%; /* Conserver les cercles */
    width: 250px; /* Augmenter la largeur */
    height: 250px; /* Augmenter la hauteur */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    color: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Ombre pour chaque fonctionnalité */
    text-align: center; /* Centrer le texte */
    padding: 1.5rem; /* Ajouter du padding pour centrer verticalement */
}

.feature-item:hover {
    transform: translateY(-10px); /* Augmenter le décalage au survol */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7); /* Accentuation de l'ombre au survol */
}

/* Icônes Bootstrap */
.feature-item .icon {
    font-size: 3rem; /* Augmenter la taille de l'icône */
    margin-bottom: 0.5rem;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6); /* Ombre pour l'icône */
}

/* Titre des fonctionnalités */
.feature-item h4 {
    font-size: 1.5rem; /* Augmenter la taille de la police */
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: white;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6); /* Ombre pour le titre */
}

/* Description */
.feature-item p {
    font-size: 1.2rem; /* Augmenter la taille de la police */
    color: rgba(255, 255, 255, 0.8);
}

/* Responsive ajusté */
@media (max-width: 768px) {
    .why-choose h2 {
        font-size: 2rem;
    }
    .features-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 colonnes pour les petits écrans */
        gap: 2rem; /* Augmenter l'écart entre les éléments */
    }
    .feature-item {
        width: 180px; /* Ajuster la largeur pour les petits écrans */
        height: 180px; /* Ajuster la hauteur pour les petits écrans */
    }
    .feature-item .icon {
        font-size: 2.5rem; /* Ajuster la taille de l'icône pour les petits écrans */
    }
    .feature-item h4 {
        font-size: 1.2rem; /* Ajuster la taille de la police pour les petits écrans */
    }
    .feature-item p {
        font-size: 0.9rem; /* Ajuster la taille de la police pour les petits écrans */
    }
    /* Masquer les câbles en responsive */
    .features-grid::before,
    .features-grid::after {
        display: none;
    }
}

@media (max-width: 480px) {
    .features-grid {
        grid-template-columns: 1fr; /* 1 colonne pour les très petits écrans */
        gap: 2rem; /* Augmenter l'écart entre les éléments */
    }
    .feature-item {
        width: 150px; /* Ajuster la largeur pour les très petits écrans */
        height: 150px; /* Ajuster la hauteur pour les très petits écrans */
    }
    .feature-item .icon {
        font-size: 2rem; /* Ajuster la taille de l'icône pour les très petits écrans */
    }
    .feature-item h4 {
        font-size: 1rem; /* Ajuster la taille de la police pour les très petits écrans */
    }
    .feature-item p {
        font-size: 0.8rem; /* Ajuster la taille de la police pour les très petits écrans */
    }
    /* Masquer les câbles en responsive */
    .features-grid::before,
    .features-grid::after {
        display: none;
    }
}

/* Ajout de décalage aléatoire */
.feature-item:nth-child(3n+1) {
    transform: translateX(-30px); /* Augmenter le décalage vers la gauche */
}
.feature-item:nth-child(3n+2) {
    transform: translateX(30px); /* Augmenter le décalage vers la droite */
}
.feature-item:nth-child(3n+3) {
    transform: translateX(-30px); /* Augmenter le décalage vers la gauche */
}

/* Animation des câbles */
.features-grid {
    position: relative;
}

.features-grid::before,
.features-grid::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    animation: cableAnimation 2s infinite;
}

.features-grid::before {
    left: 33.33%;
}

.features-grid::after {
    left: 66.66%;
}

@keyframes cableAnimation {
    0% {
        height: 0;
    }
    50% {
        height: 100%;
    }
    100% {
        height: 0;
    }
}

/* Animation du mot "Vision" */
.vision-text {
    position: absolute;
    font-size: 2rem;
    font-weight: bold;
    color: white;
    animation: visionMove 5s infinite;
    pointer-events: none; /* Pour que le texte n'interfère pas avec les interactions des éléments */
}

@keyframes visionMove {
    0% {
        top: 0;
        left: 0;
        transform: translate(0, 0);
    }
    25% {
        top: 50%;
        left: 25%;
        transform: translate(-50%, -50%);
    }
    50% {
        top: 100%;
        left: 50%;
        transform: translate(-50%, -100%);
    }
    75% {
        top: 50%;
        left: 75%;
        transform: translate(-50%, -50%);
    }
    100% {
        top: 0;
        left: 100%;
        transform: translate(-100%, 0);
    }
}