/* Styles généraux pour le header */
.header {
    display: flex;
    align-items: center; /* Centrer verticalement les éléments */
    justify-content: space-between; /* Espacement entre le logo et la nav */
    padding: 15px 30px; /* Espacement interne du header */
    background-color: transparent; /* Commence avec un fond transparent */
    color: #ffffff; /* Couleur du texte en blanc pour le fond transparent */
    box-shadow: none; /* Pas d'ombre au départ */
    position: fixed; /* Fixer le header en haut de la page */
    top: 0; /* Positionné en haut */
    width: 100%; /* Prendre toute la largeur */
    z-index: 1000; /* S'assurer qu'il est au-dessus des autres éléments */
    transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease; /* Transition pour les effets */
}

/* Styles pour le logo */
.header .logo {
    display: flex; /* Affichage en flex pour aligner l'image et le texte */
    align-items: center; /* Centrer verticalement */
}

.header .logo-img {
    width: 150px; /* Réduire la largeur du logo */
    height: auto; /* Hauteur automatique pour conserver les proportions */
    margin-right: 10px; /* Espacement entre l'image et le texte */
    margin-top: 80px; /* Garder le margin-top sur toutes les tailles d'écran */
}

/* Styles pour la nav */
.header nav {
    display: flex; /* Flexbox pour la navigation */
    justify-content: flex-end; /* Aligner la navigation à droite */
    flex-grow: 1; /* La nav prend l'espace restant */
}

/* Styles de la nav (liens) */
.header nav .nav-link {
    color: white; /* Texte blanc */
    text-decoration: none; /* Pas de soulignement */
    margin-left: 20px; /* Espacement entre les liens */
    font-size: 1.1rem; /* Taille de police */
}

/* Styles pour la nav en responsive */
@media (max-width: 768px) {
    .header {
        flex-direction: column; /* Changer la direction du flex en colonne */
        align-items: center; /* Centrer verticalement */
        justify-content: center; /* Espacement entre le logo et la nav */
        padding: 15px; /* Ajuster l'espacement */
    }

    .header .logo-img {
        margin-top: 0; /* Annuler le margin-top uniquement sur petit écran */
    }

    .header nav {
        width: 100%; /* Utiliser la largeur complète de l'écran */
        justify-content: center; /* Centrer la navigation */
    }

    .header nav .nav-link {
        margin: 10px 0; /* Espacement vertical pour les liens */
    }
}

/* État lorsque l'utilisateur fait défiler la page */
.header.scrolled {
    background-color: #ffffff; /* Fond blanc lorsque défilé */
    color: #343a40; /* Changer la couleur du texte pour le contraste */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Ombre sous le header */
}

/* Modifier le texte du logo lorsque défilé */
.header.scrolled .logo-text {
    color: #343a40; /* Changer la couleur du texte du logo */
}

/* Modifier la couleur du texte de la navigation */
.header.scrolled nav .nav-link {
    color: #343a40; /* Changer la couleur du texte des liens */
}
