/* Section principale sans bordure */
.click-design {
    max-width: 800px;
    margin: 50px auto;
    padding: 30px;
    background: #1c1c1e; /* Fond noir */
    color: #ffffff; /* Texte blanc */
    transition: all 0.3s ease;
    border-radius: 10px; /* Bords arrondis pour un effet moderne */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Ombre pour profondeur */
}

/* Titre principal */
.click-design h1 {
    font-size: 2.5rem;
    color: #9b59b6; /* Violet pour le titre principal */
    text-align: center;
    margin-bottom: 20px;
}

/* Texte descriptif */
.click-design p {
    margin-bottom: 20px;
    line-height: 1.8;
    color: #e0e0e0; /* Texte gris clair pour un contraste doux */
}

/* Sous-titres */
.click-design h2 {
    color: #8e44ad; /* Violet pour le sous-titre */
    margin-top: 20px;
    font-size: 1.8rem;
    margin-bottom: 10px;
}

/* Liste des services */
.click-design-services-list {
    list-style: none; /* Suppression des puces */
    padding-left: 0;
    margin: 20px 0;
}

/* Élément de la liste */
.click-design-services-list li {
    background-color: #2c2c2e; /* Fond noir-gris foncé */
    margin: 10px 0;
    padding: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #ffffff; /* Texte blanc */
    position: relative;
    overflow: hidden;
    border-radius: 5px; /* Coins arrondis pour les éléments de la liste */
}

/* Effet au survol des éléments de la liste */
.click-design-services-list li:hover {
    color: #9b59b6; /* Texte violet au survol */
    background-color: transparent; /* Suppression du fond au survol */
    transform: translateY(-3px); /* Légère élévation */
}

/* Description des services */
.click-design-service-description {
    padding: 15px;
    margin-top: 20px;
    background-color: transparent; /* Fond transparent pour s'intégrer sans bordure */
    color: #ffffff; /* Texte blanc */
}

/* Titre des descriptions */
.click-design-service-description h3 {
    color: #9b59b6; /* Violet pour les titres des descriptions */
    margin-bottom: 10px;
}

/* Bouton retour */
.click-design-back-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #9b59b6; /* Violet pour le bouton */
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-weight: bold;
}

/* Effet au survol du bouton */
.click-design-back-button:hover {
    background-color: #8e44ad; /* Violet plus foncé au survol */
}

/* Style global */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #1c1c1e; /* Fond noir profond */
    color: #ffffff; /* Texte blanc */
    line-height: 1.7;
}

/* Media queries pour rendre la section responsive */
@media (max-width: 768px) {
    .click-design {
        padding: 20px; /* Réduit le padding sur mobile */
        margin: 20px; /* Réduit la marge sur mobile */
    }

    .click-design h1 {
        font-size: 2rem; /* Réduit la taille du titre sur mobile */
    }

    .click-design h2 {
        font-size: 1.5rem; /* Réduit la taille du sous-titre sur mobile */
    }

    .click-design-services-list li {
        padding: 10px; /* Ajuste le padding des éléments de la liste sur mobile */
    }

    .click-design-back-button {
        padding: 8px 16px; /* Ajuste le padding du bouton sur mobile */
        font-size: 0.9rem; /* Réduit la taille de la police du bouton */
    }
}
