/* Conteneur principal */
.contact-page-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #1c1c1e; /* Fond sombre */
    padding: 20px;
  }
  
  /* Formulaire */
  .contact-form {
    background: #2c2c2e; /* Noir-gris */
    padding: 30px;
    border-radius: 10px; /* Bord arrondi */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Ombre subtile */
    max-width: 600px;
    width: 100%;
    color: #ffffff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .contact-form:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.7);
  }
  
  /* Titre */
  .contact-form-header {
    font-size: 2rem;
    color: #9b59b6; /* Violet */
    text-align: center;
    margin-bottom: 10px;
  }
  
  .contact-form-description {
    font-size: 1rem;
    color: #e0e0e0; /* Texte gris clair */
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Groupes de champs */
  .contact-form-group {
    margin-bottom: 20px;
  }
  
  .contact-form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
    color: #9b59b6; /* Violet */
  }
  
  /* Champs */
  .contact-input,
  .contact-textarea {
    width: 100%;
    padding: 12px 15px;
    font-size: 1rem;
    border: 1px solid #444; /* Bordure discrète */
    background-color: #1c1c1e; /* Fond sombre */
    color: #ffffff;
    border-radius: 5px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .contact-input:focus,
  .contact-textarea:focus {
    border-color: #9b59b6;
    box-shadow: 0 0 8px rgba(155, 89, 182, 0.5);
    outline: none;
  }
  
  /* Bouton */
  .contact-submit-button {
    display: block;
    width: 100%;
    padding: 12px 20px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #ffffff;
    background: linear-gradient(135deg, #9b59b6, #8e44ad); /* Dégradé violet */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .contact-submit-button:hover {
    background-color: #8e44ad; /* Violet foncé */
    box-shadow: 0 4px 15px rgba(155, 89, 182, 0.5);
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .contact-form {
      padding: 20px;
    }
  
    .contact-form-header {
      font-size: 1.8rem;
    }
  
    .contact-submit-button {
      font-size: 1rem;
    }
  }
  