/* Section principale */
.services {
    padding: 0;
    background: linear-gradient(135deg, #000000, #1a1a1a); /* Fond noir avec un léger dégradé */
    color: #f8f9fa; /* Texte clair pour contraste */
    text-align: center;
}

/* Animation du titre avec logo rebondissant */
.animated-title {
    margin-bottom: 30px;
}

.bouncing-logo {
    height: 50px;
    animation: bounce 5s infinite linear;
}

@keyframes bounce {
    0%, 100% { transform: translateY(-50%); }
    50% { transform: translateY(-60%); }
}

/* Conteneur des sections */
.service-sections {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 20px;
}

/* Style des sections */
.service-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

/* Inversion pour les sections alternées */
.service-section.reverse {
    flex-direction: row-reverse;
}

/* Conteneur de l'image */
.service-section .image-wrapper {
    position: relative;
    flex: 1;
    height: 300px;
    overflow: hidden; /* Empêche l'image de déborder */
    border-radius: 8px;
    transition: transform 0.3s ease;
}

/* Style de l'image elle-même */
.service-section .image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* L'image s'adapte sans être rognée */
    transition: transform 0.3s ease;
    cursor: pointer; /* Ajoute le curseur cliquable */
}

/* Effet d'agrandissement au survol de l'image */
.service-section .image-wrapper:hover .image {
    transform: scale(1.1); /* Agrandissement de l'image au survol */
}

/* Style du texte */
.service-section .text {
    flex: 1;
    padding: 20px;
    text-align: left;
    color: #e9ecef; /* Texte clair pour le fond noir */
}

/* Titre dans le texte */
.service-section h3 {
    font-size: 1.8rem;
    color: #ffcc00; /* Une couleur or élégante pour le titre */
    margin-bottom: 10px;
}

/* Paragraphe du texte */
.service-section p {
    font-size: 1rem;
    color: #f1f1f1; /* Texte légèrement grisé */
}

/* Lien dans le texte */
.service-section .section-link {
    text-decoration: none;
    color: #ffcc00;
    font-weight: bold;
}

.service-section .section-link:hover {
    text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
    .service-section {
        flex-direction: column; /* Sections en colonne sur mobile */
        text-align: center;
    }

    .service-section.reverse {
        flex-direction: column; /* Colonne même pour les sections inversées */
    }

    .service-section .image-wrapper {
        height: 250px; /* Hauteur ajustée pour mobile */
        width: 100%; /* Assurer que l'image prend toute la largeur */
    }

    .service-section .text {
        text-align: center;
        padding: 10px; /* Réduire le padding pour mobile */
    }
}

@media (max-width: 480px) {
    .service-section .image-wrapper {
        height: 200px; /* Taille d'image réduite sur très petits écrans */
        width: 100%; /* Assurer que l'image prend toute la largeur */
    }

    .service-section .text {
        padding: 5px; /* Réduire encore plus le padding pour très petits écrans */
    }
}

/* Section Logo */
.logo-section {
    background: linear-gradient(45deg, #000000, #6a0dad, #ffcc00); /* Dégradé initial */
    background-size: 300% 300%; /* Taille pour l'animation fluide */
    animation: backgroundFade 6s infinite; /* Animation fluide du dégradé */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6), 0 8px 25px rgba(0, 0, 0, 0.4); /* Ombre principale et ombre secondaire */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Section plus petite */
    padding: 10px; /* Légère marge interne */
    margin-bottom: 20px; /* Espacement avec la section suivante */
    border-radius: 8px; /* Coins arrondis pour une esthétique moderne */
    position: relative; /* Positionnement relatif pour l'overlay */
}

/* Animation de fondu de couleurs */
@keyframes backgroundFade {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Overlay transparent avec opacité */
.logo-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Overlay sombre avec opacité */
    z-index: 0; /* Assure que l'overlay est en dessous du contenu */
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.4); /* Ombre interne subtile */
}

/* Contenu de la section */
.logo-section > * {
    position: relative;
    z-index: 1; /* Assure que le contenu est au-dessus de l'overlay */
}

/* Logo rebondissant */
.bouncing-logo2 {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 50px;
    animation: bounceDVD 10s infinite linear; /* Animation qui déplace le logo et le fait rebondir */
}

/* Animation de mouvement du logo DVD-like */
@keyframes bounceDVD {
    0% {
        left: 0;
        top: 50%;
    }
    25% {
        left: calc(100% - 60px); /* Déplace le logo à droite sans atteindre le bord */
        top: 20%;   /* Ajuste la position verticale */
    }
    50% {
        left: calc(100% - 60px); /* Le logo reste à droite */
        top: 80%;   /* Ajuste la position verticale */
    }
    75% {
        left: 0;    /* Déplace le logo à gauche */
        top: 20%;   /* Ajuste la position verticale */
    }
    100% {
        left: 0;    /* Le logo revient à gauche */
        top: 50%;   /* Ajuste la position verticale */
    }
}