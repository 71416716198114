/* Section principale avec un background statique */
.besoins {
    padding: 4rem 1rem;
    text-align: center;
    color: white;
    position: relative;
    background: linear-gradient(45deg, black, violet, gold); /* Dégradé initial */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6); /* Ombre principale */
}

/* Overlay transparent */
.besoins::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Overlay sombre avec plus d'opacité */
    z-index: 0;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.5); /* Ombre interne */
}

/* Positionnement du contenu */
.besoins > * {
    position: relative;
    z-index: 1;
}

/* En-tête de la section */
.besoins-header {
    margin-bottom: 2rem;
}

.besoins-title {
    font-size: 2.5rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    color: white;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8); /* Ombre pour le titre */
}

.besoins-subtitle {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.9); /* Augmentation de l'opacité */
}

/* Grille des cartes */
.besoins-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-top: 2rem;
}

/* Style des cartes */
.besoins-card {
    background: rgba(255, 255, 255, 0.2); /* Augmentation de l'opacité */
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3); /* Augmentation de l'opacité */
    border-radius: 12px;
    padding: 2rem 1rem;
    text-align: center;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Ombre pour chaque carte */
}

/* Glow effect */
.besoins-card::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.2), transparent 60%); /* Augmentation de l'opacité */
    transition: opacity 0.4s ease;
    opacity: 0;
    z-index: 0;
    transform: rotate(45deg);
}

.besoins-card:hover::before {
    opacity: 1;
}

/* Zoom et déplacement */
.besoins-card:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
}

.icon {
    font-size: 3rem;
    margin-bottom: 15px;
    color: var(--highlight-color);
    transition: transform 0.4s ease, color 0.4s ease;
}

.besoins-card:hover .icon {
    transform: scale(1.3);
    color: gold;
}

/* Titre et description */
.besoins-card h3 {
    font-size: 1.5rem;
    margin: 1rem 0 0.5rem;
    color: white;
    transition: color 0.3s ease;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6); /* Ombre pour le titre */
}

.besoins-card p {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.9); /* Augmentation de l'opacité */
}

/* Responsive ajustement */
@media (max-width: 768px) {
    .besoins-title {
        font-size: 2rem;
    }

    .besoins-cards {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .besoins-card {
        padding: 1.5rem 1rem;
    }
}